import "bootstrap/dist/css/bootstrap.min.css"
import { useStaticQuery, graphql } from "gatsby"
import Button from "react-bootstrap/Button"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import Nav from "react-bootstrap/Nav"
import PropTypes from "prop-types"
import React from "react"
import "./header.css"

const Header = () => {
  // const { strapiGlobal } = useStaticQuery(query)
  const data = useStaticQuery(graphql`
    {
      allStrapiHeading(sort: { fields: [id] }) {
        nodes {
          id
          name
          link
          sub_headings {
            Name
            Link
          }
        }
      }
      strapiTitle {
        MobileTitle
        Title
        Logo {
          alternativeText
          url
        }
      }
    }
  `)
  return (
    <Navbar
      className="navbar navbar-expand-lg navbar-dark fixed-top"
      expand="lg"
    >
      <Navbar.Brand href="/">
        <img
          src={data.strapiTitle.Logo[0].url}
          class="navbar-logo"
          alt-text={data.strapiTitle.Logo[0].alternativeText}
        />
        <p className="desktop">{data.strapiTitle.Title}</p>
        <p className="mobile">{data.strapiTitle.MobileTitle}</p>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {data.allStrapiHeading.nodes.map(heading => {
            if (heading.sub_headings.length === 0) {
              return (
                <Nav.Link href={heading.link} className="nav-vertical-center">
                  {heading.name}
                </Nav.Link>
              )
            } else {
              return (
                <NavDropdown
                  title={heading.name}
                  id="basic-nav-dropdown"
                  className="bg-primary nav-vertical-center"
                >
                  {heading.sub_headings.map(subHeading => {
                    return (
                      <NavDropdown.Item
                        href={subHeading.Link}
                        style={{ color: "#FFFFFF80" }}
                      >
                        {subHeading.Name}
                      </NavDropdown.Item>
                    )
                  })}
                </NavDropdown>
              )
            }
          })}
          <Nav.Link>
            <Button variant="success" href="/page/donate-now">
              Donate Now
            </Button>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
